import RouterApp from "./pages";
import { Provider as TooltipProvider } from '@radix-ui/react-tooltip';

function App() {
  return (
    <TooltipProvider>
      <RouterApp />
    </TooltipProvider>
  );
}

export default App;
