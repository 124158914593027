import * as Tooltip from '@radix-ui/react-tooltip';
import styled from 'styled-components';

export interface TooltipProps {
  children: React.ReactNode,
  content: string,
}

const TooltipUI = ({children, content}: TooltipProps): JSX.Element => {
  return (
    <Root delayDuration={400}>
    <Trigger>
      {children}
    </Trigger>
    <Content>
      {content}
    </Content>
    </Root>
  )
}

const Root = styled(Tooltip.Root)`
`

const Trigger = styled(Tooltip.Trigger)`
  background: none;
  border: none;
  padding: 0px;
  margin: none;
  color: white;
  cursor: pointer;
`

const Content = styled(Tooltip.Content)`
  /* background: rgba(255, 255, 255, 0.1); */
  background: #b8d6f0;
  border-radius: 99px;
  padding: 6px;
  font-size: 10px;
  text-transform: uppercase;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.8);
`

export default TooltipUI;