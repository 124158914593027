import { useState } from 'react'
import styled from 'styled-components'
import TooltipUI from '../../components/tooltip'
import About from './about'
import './index.css'

type PageType = "about" | "faq" | "participer" | "partenariats" | "credits" | "";

const Homepage = (): JSX.Element => {
  const pages: {[key: string]: any} = {
    "about": <About />,
    "faq": "faq",
    "participer": "participer",
    "partenariats": "partenariats",
    "credits": "credits",
    "": "",
  } 
  const [currentPage, setCurrentPage] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShoutboxOpen, setIsShoutboxOpen] = useState(false);

  return (
    <BodyContainer>
      <DescriptionContainer isVisible={currentPage === ""}>
        <VerticalBar length={80} /><br />
        <span>Répertoire de <strong>ressources</strong> roleplay</span>
      </DescriptionContainer>
      <BottomBarContainer isVisible={currentPage === ""}>
        <VerticalBar length={140} />
      </BottomBarContainer>
      <StatsContainer isVisible={currentPage === ""}>
        2000 avatars<br />
        1000 faceclaims<br />
        5 codes<br />
        40 graphistes
      </StatsContainer>
      <HeaderContainer isVisible={currentPage === ""}>
        <Sun isVisible={currentPage === ""} />
        <Sun2 isVisible={currentPage === ""} />
        <HeaderImage isVisible={currentPage === ""} />
      </HeaderContainer>
      <MenuButton onClick={() => { setIsMenuOpen(!isMenuOpen)}}>
        <MenuIcon className="material-symbols-outlined">
          menu
        </MenuIcon>
        {isMenuOpen ? 'Fermer ' : 'Ouvrir '} le menu
      </MenuButton>
      <Menu isOpen={isMenuOpen}>
        <MenuItem onClick={() => {setCurrentPage("about")}} isVisible={isMenuOpen} delay={1}>
          About
        </MenuItem>
        <MenuItem onClick={() => {setCurrentPage("")}} isVisible={isMenuOpen} delay={2}>
          Avatars
        </MenuItem>
        <MenuItem onClick={() => {}} isVisible={isMenuOpen} delay={3}>
          Faceclaims
        </MenuItem>
        <MenuItem onClick={() => {}} isVisible={isMenuOpen} delay={4}>
          Codes
        </MenuItem>
        <MenuItem onClick={() => {setCurrentPage("faq")}} isVisible={isMenuOpen} delay={4}>
          Faq
        </MenuItem>
        <MenuItem onClick={() => {setCurrentPage("participer")}} isVisible={isMenuOpen} delay={3}>
          Participer
        </MenuItem>
        <MenuItem onClick={() => {setCurrentPage("partenariats")}} isVisible={isMenuOpen} delay={2}>
          Partenariats
        </MenuItem>
        <MenuItem onClick={() => {setCurrentPage("credits")}} isVisible={isMenuOpen} delay={1}>
          Crédits
        </MenuItem>
      </Menu>
      <DiscordContainer isVisible={currentPage === ""}>
        <span>Rejoins le <strong>discord</strong>!</span>
        <DiscordIcon src="https://i.imgur.com/9wXtRbI.png" />
      </DiscordContainer>
      <MajContainer isVisible={currentPage === ""}>
        <MajIcon src="https://i.imgur.com/eGjtDfd.png" />
        <span><strong>Dernière màj: </strong>XX/XX/2022</span>
      </MajContainer>
      <PartenariatGrid>
        <TooltipUI content="Nom du partenaire">
          <PartenariatImg src="https://i.imgur.com/3z4zUTv.png" />
        </TooltipUI>
        <TooltipUI content="Nom du partenaire">
          <PartenariatImg src="https://i.imgur.com/3z4zUTv.png" />
        </TooltipUI>
        <TooltipUI content="Nom du partenaire">
          <PartenariatImg src="https://i.imgur.com/3z4zUTv.png" />
        </TooltipUI>
        <TooltipUI content="Nom du partenaire">
          <PartenariatImg src="https://i.imgur.com/3z4zUTv.png" />
        </TooltipUI>

        <TooltipUI content="Nom du partenaire">
          <PartenariatImg src="https://i.imgur.com/3z4zUTv.png" />
        </TooltipUI>
        <TooltipUI content="Nom du partenaire">
          <PartenariatImg src="https://i.imgur.com/3z4zUTv.png" />
        </TooltipUI>
        <TooltipUI content="Nom du partenaire">
          <PartenariatImg src="https://i.imgur.com/3z4zUTv.png" />
        </TooltipUI>
        <TooltipUI content="Nom du partenaire">
          <PartenariatImg src="https://i.imgur.com/3z4zUTv.png" />
        </TooltipUI>

        <TooltipUI content="Nom du partenaire">
          <PartenariatImg src="https://i.imgur.com/3z4zUTv.png" />
        </TooltipUI>
        <TooltipUI content="Nom du partenaire">
          <PartenariatImg src="https://i.imgur.com/3z4zUTv.png" />
        </TooltipUI>
        <TooltipUI content="Nom du partenaire">
          <PartenariatImg src="https://i.imgur.com/3z4zUTv.png" />
        </TooltipUI>
        <TooltipUI content="Nom du partenaire">
          <PartenariatImg src="https://i.imgur.com/3z4zUTv.png" />
        </TooltipUI>
      </PartenariatGrid>
      <ShoutboxContainer onClick={() => {setIsShoutboxOpen(!isShoutboxOpen)}}>
        <span>{isShoutboxOpen ? 'Fermer ' : 'Ouvrir '} la shoutbox</span>
        <ShoutboxIcon className="material-symbols-outlined">
          reviews
        </ShoutboxIcon>
      </ShoutboxContainer>
      <ShoutboxMenu isOpen={isShoutboxOpen}>
        <iframe src="https://www5.cbox.ws/box/?boxid=922341&boxtag=QsqMuQ" width="300px" height="390" allow="autoplay" allowTransparency={true} frameBorder="0" marginHeight={0} marginWidth={0} scrolling="auto"></iframe>
      </ShoutboxMenu>
      <CopyrightContainer>
        <TooltipUI content="Créé par Azria, Cheshireftw et Palourde | Version 3 | 2020-2022">
          <CopyrightIcon className="material-symbols-outlined">
            copyright
          </CopyrightIcon>
        </TooltipUI>
      </CopyrightContainer>
      <PageContainer isVisible={!(currentPage === "")}>
        {pages[currentPage]}
      </PageContainer>
    </BodyContainer>
  )
}

interface MenuItemProps {
  isVisible: boolean,
  delay: number,
}

interface MenuProps {
  isOpen: boolean,
}

interface BarProps {
  length: number,
}

interface DisappearProps {
  isVisible: boolean,
}

const PageContainer = styled.div<DisappearProps>`
  position: absolute;
  width: 580px;
  background: white;
  height: calc(100vh - 80px);
  top: ${(props) => props.isVisible ? "60px" : "100vh"};
  transition: ease-in-out 1s all;
  left: 300px;
  box-sizing: border-box;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const StatsContainer = styled.div<DisappearProps>`
  position: fixed;
  top: 50vh;
  right: 0px;
  padding: 20px 20px 0 100px;
  text-align: right;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 16px;
  border-top: 1px solid white;

  right: ${(props) => !props.isVisible ? "-340px" : "0px"};

  transition: ease-in-out 1s all;
`

const CopyrightContainer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: none;
  padding: none;
  border: none;

  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
`;

const CopyrightIcon = styled.span`
`;

const ShoutboxMenu = styled.div<MenuProps>`
  padding: 20px;
  border-top: 1px solid white;
  width: 300px;
  position: fixed;
  right: 0px;
  top: 60px;

  right: ${(props) => !props.isOpen ? "-340px" : "0px"};

  transition: ease-in-out 1s all;
`;

const ShoutboxIcon = styled.span`
  margin-left: 10px;
`;

const ShoutboxContainer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  padding: none;
  border: none;

  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
`;

const PartenariatImg = styled.img`
  width: 35px;
  height: 35px;
  filter: grayscale(1);
  border: 4px solid white;
  transition: ease-in-out 0.2s all;

  :hover {
    filter: grayscale(0);
  }
`

const PartenariatElem = styled.div`
  cursor: pointer;
`

const PartenariatGrid = styled.div`
  position: absolute;
  bottom: 20px;
  left: 30px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
`

const MajIcon = styled.img`
  width: 46px;
  margin-right: 10px;
`

const MajContainer = styled.div<DisappearProps>`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;

  left: calc(50vw + 130px);
  top: calc(50vh + 140px);
  
  transition: ease-in-out 0.2s all;

  opacity: ${(props) => props.isVisible ? '1' : '0'};
`

const DiscordIcon = styled.img`
  width: 46px;
  margin-left: 10px;
  animation: float 4s infinite linear;
`

const DiscordContainer = styled.button<DisappearProps>`
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: none;
  border: none;
  padding: none;
  color: ${(props) => props.isVisible ? 'white' : 'transparent'};
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;

  left: ${(props) =>`${props.isVisible ? 'calc(50vw - 320px)' : '850px'}`};
  top: calc(50vh - 220px);
  transition: ease-in-out 1s all;

  cursor: pointer;
`

const BottomBarContainer = styled.div<DisappearProps>`
  position: absolute;
  bottom: 0;
  left: 50vw;

  bottom: ${(props) => !props.isVisible ? "-340px" : "0px"};

  transition: ease-in-out 1s all;
`

const VerticalBar = styled.div<BarProps>`
  width: 1px;
  height: ${(props) => `${props.length}px`};
  background: white;
`

const DescriptionContainer = styled.div<DisappearProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 400px;
  left: calc(50vw - 200px);
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;

  margin-top: ${(props) => !props.isVisible ? "-300px" : "0px"};

  transition: ease-in-out 1s all;
`

const MenuItem = styled.button<MenuItemProps>`
  background: none;
  border: none;

  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 16px;
  padding: 4px 0;

  margin-right: ${(props) => !props.isVisible ? "100px" : "0px"};

  transition: ease-in-out 0.8s margin ${(props) => `${props.delay / 10}s`}, ease-in-out 0.2s padding;

  cursor: pointer;

  :hover {
    color: #77a7c8 !important;
    padding-right: 10px;
  }
`

const Menu = styled.div<MenuProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: flex-end;

  width: 250px;
  border-top: 1px solid white;
  margin-top: 60px;
  box-sizing: border-box;
  padding: 10px 0px;

  margin-left: ${(props) => !props.isOpen ? "-300px" : "0px"};

  transition: ease-in-out 1s all;
`;

const MenuIcon = styled.span`
  margin-right: 10px;
`;

const MenuButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: white;
  background: none;
  border: none;
  position: absolute;
  margin: 20px;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
`;

const HeaderImage = styled.div<DisappearProps>`
  background: url('https://i.imgur.com/UngiJoL.png') no-repeat;
  background-size: contain;
  width: 500px;
  height: 439px;
  position: absolute;
  left: ${(props) => props.isVisible ? "calc(50% - 250px)" : "600px"};
  transition: ease-in-out 1s all;
`

const Sun2 = styled.div<DisappearProps>`
  width: 350px;
  height: 350px;
  background: url('https://i.imgur.com/ZxLOANj.png');
  background-size: contain;
  animation: rotation_anticlockwise 40s infinite linear;
  position: absolute;
  left: ${(props) => props.isVisible ? "calc(50% - 175px)" : "678px"};
  transition: ease-in-out 1s all;
`

const Sun = styled.div<DisappearProps>`
  position: absolute;
  width: 507px;
  height: 507px;
  background: url('https://i.imgur.com/KkY4vYS.png');
  background-size: contain;
  animation: rotation 26s infinite linear;
  left: ${(props) => props.isVisible ? "calc(50% - 253px)" : "600px"};
  transition: ease-in-out 1s all;
`

const HeaderContainer = styled.div<DisappearProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  position: fixed;

  margin: ${(props) => props.isVisible ? "0 0 0 0" : "0 0 0 300px"};

  transition: ease-in-out 1s all;
`

const BodyContainer = styled.div`
`

export default Homepage;