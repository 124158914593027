import styled from 'styled-components'

export interface TitleProps {
  children: React.ReactNode
}

const Title = ({ children }: TitleProps): JSX.Element => {
  return (
    <MainContainer>
      {children}
    </MainContainer>
  )
}

const MainContainer = styled.div`
  width: 100%;
  background: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  padding: 8px 12px;
  font-size: 12px;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #ec756c;
  border: 1px solid rgba(0, 0, 0, 0.09);
  outline: 3px solid rgba(0, 0, 0, 0.01);
  margin-bottom: 20px;
`

export default Title;