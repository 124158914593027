import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import Homepage from './home'

const RouterApp = (): JSX.Element => {
  return (
    <BrowserRouter basename="">
      <Routes>
        <Route path="/" element={<Homepage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default RouterApp;