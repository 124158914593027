import styled from "styled-components";

const Divider = (): JSX.Element => {
  return (
    <DividerLine />
  )
}

const DividerLine = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.05);
  margin: 20px 0px;
`

export default Divider;