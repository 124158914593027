import styled from 'styled-components';
import Divider from '../../../components/divider';
import Title from '../../../components/title';

const About = (): JSX.Element => {
  return (
    <Container>
      <Title>Le projet Hall of Fame</Title>
      Tout d'abord, laisse-moi te souhaiter la <strong>bienvenue</strong> sur Hall of Fame et te remercier de prendre le temps d'en connaître plus sur le projet.<br/>
      Hall of Fame, ce n'est autre qu'un <em>répertoire de ressources</em> destiné à la formidable communauté du roleplay illustré francophone.<br/><br/>

      Je suis partie du constat que si dans la communauté rpg à avatars IRL beaucoup de ressources (que ce soit avatars, répertoires de faceclaims) sont disponibles, ce n'est
      pas vraiment le cas dans le monde du roleplay illustré. Et puisque j'ai sans arrêt soif de création et de partage,
      je me suis mis en tête de rassembler mes créations et quelques tips au même endroit.<br /><br />

      En bref, Hall of Fame, c'est des <em>personnes formidables</em> qui prennent de leur temps pour créer et partager du contenu utilisable par tous.<br /><br />

      En cas de doutes sur le fonctionnement de ce répertoire, je t'invite à aller lire l'ensemble de la <strong>FAQ</strong> ainsi que les pages relatives à chaque libre service.
      Elles te donneront toutes les informations nécessaires pour pouvoir utiliser les ressources de HoF.

      <Divider />
      <Title>Descriptif de la dernière mise-à-jour</Title>
      <Block>
        - <strong>nouvelle version: </strong>version 3.0 réalisée par <em>azria</em>.<br /><br />
        - <strong>700+ avatars: </strong>étant donné le délai entre les deux màj, le nombre d'avatars est absolument rocambolesque! merci les chefs!<br /><br />
        - <strong>ouverture de la catégorie code: </strong>désormais, vous pouvez également profiter du talent des codeurs de la communauté. on espère que ce libre-service va pouvoir autant se développer que son compatriote graphique.<br /><br />
        - <strong>10 codes: </strong>petit nombre pour démarrer et de quoi vous donnez le premier goût du LS.<br /><br />
        - <strong>400+ faceclaims: </strong>on continue de rajouter des bouilles pour vous inspirer.<br /><br />
        - <strong>10+ contributeurs: </strong>on agrandit la fine équipe, merci encore mille fois!<br /><br />
        - <strong>refonte du fonctionnement: </strong>vous pouvez désormais créer un compte et uploader vous-même des avatars, des codes ou des faceclaims. toutes les informations nécessaires sont sur le discord de HoF!
      </Block>

      <Divider />
    </Container>
  )
}

const Block = styled.div`
  box-sizing: border-box;
  border-left: 8px solid rgba(0, 0, 0, 0.06);
  padding: 8px 8px 8px 20px;
  line-height: 1.5;
`

const Container = styled.div`
  font-size: 12px;
  font-family: 'Open Sans', sans-serif;

  em {
    color: #ec756c;
  }

  strong {
    color: #77a7c8;
  }
`;

export default About;